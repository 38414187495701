<template>
  <div>
    <!-- Contests -->
    <b-row align-h="between">
      <b-col><h2>Contests</h2></b-col>
    </b-row>
    <b-row class="match-height">
      <b-col cols="12" md="6" v-for="contest in contests" :key="contest.id">
        <contest-card :data="contest" />
      </b-col>
    </b-row>
    <!-- Contests End-->
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BImg,
  BCarousel,
  BCarouselSlide,
  BRow,
  BCol,
  BMedia,
  BTab,
  BTabs,
} from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";
import ContestCard from "@/views/pages/contests/ContestCard.vue";
import store from '@/store/index'

export default {
  metaInfo: {
    title: 'Contests',
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BCarousel,
    BCarouselSlide,
    BRow,
    BCol,
    BMedia,
    BTabs,
    BTab,
    ContestCard,
  },
  computed: {
    contests() {
       return store.getters['contests/all']
    }
  },
  watch: {
    async contests() {
      return this.$store.getters['contests/all']
    }
  },
  mounted() {
      this.$store.dispatch('contests/getContests', '')
    // } 
  },
  setup() {
    // App Name
    const { appLogoImage } = $themeConfig.app;
    return {
      appLogoImage,
    };
  },
};
</script>

<style>
  
</style>
