<template>
  <div>
    <b-card
      v-if="data"
      no-body
      :img-src="data.banner"
      :img-alt="data.title"
      img-top
      class="border-primary shadow flex-grow-1"
    >
      <b-card-body class="pb-1">
        <b-card-title class="mb-0 text-primary">
          <span v-if="data.title" class="font-weight-bolder">{{
            data.title
          }}</span>
        </b-card-title>
        <div v-if="data.description" v-html="data.description"></div>
        <b-button
          v-if="data.category === 'survey'"
          variant="primary"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          @click="showSurveyModal"
          >Particiapte Now</b-button
        >
      </b-card-body>
    </b-card>
    <b-modal
      v-if="data.category === 'survey'"
      :key="data.id"
      :id="data.id"
      centered
      size="lg"
      :title="data.title"
      cancel-disabled
      footer-class="d-none"
    >
      <validation-observer ref="surveyForm" #default="{ invalid }">
        <b-form class="mt-2" @submit.prevent="submitSurvey">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="How will a gender-equal world benefit you? "
                label-for="survey-q1"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Question"
                  vid="question"
                  rules="required"
                >
                  <b-form-textarea
                    id="survey-q1"
                    v-model="question1"
                    :state="errors.length > 0 ? false : null"
                    name="survey-q1"
                    rows="5"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="And how will you contribute towards building such a world?"
                label-for="survey-q2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Question"
                  vid="question"
                  rules="required"
                >
                  <b-form-textarea
                    id="survey-q2"
                    v-model="question2"
                    :state="errors.length > 0 ? false : null"
                    name="survey-q2"
                    rows="5"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            type="submit"
            variant="primary"
            :disabled="invalid"
            class="float-right"
          >
            Submit
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import {
  BCard,
  BImg,
  BCardBody,
  BCardTitle,
  BButton,
  BForm,
  BFormGroup,
  BFormTextarea,
  BModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from "@/store/api/api";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BImg,
    BCardBody,
    BCardTitle,
    BButton,
    BForm,
    BFormGroup,
    BFormTextarea,
  },
  directives: {
    "b-modal": BModal,
    Ripple,
  },
  data() {
    return {
      question1: "",
      question2: "",
      required,
    };
  },
  methods: {
    showSurveyModal() {
      this.$bvModal.show(this.data.id);
    },
    hideSurveyModal() {
      this.$bvModal.hide(this.data.id);
    },
    submitSurvey() {
      this.$refs.surveyForm.validate().then((success) => {
        if (success) {
          let response = {
            surveySlug: "h1NC4WfZPQZwDq7HeKz9M6",
            responseSlug: "ZvRstX7wZ5L6vANLIFlXRS",
            response: {
              slug: "h1NC4WfZPQZwDq7HeKz9M6",
              name: this.$store.state.account.user.name,
              bookingNumber: this.$store.state.account.user.bookingNumber,
              question1: this.question1,
              question2: this.question2,
            },
            surveyUrl: "https://equiverse.hummz.events/h1NC4WfZPQZwDq7HeKz9M6",
            urlParams: {},
            location: {
            },
            completeReason: "qualified",
            startTime: this.$date().toISOString(),
            endTime: this.$date().toISOString(),
            surveyDuration: 0,
          };
          api.post("/surveys/response", response)
          this.hideSurveyModal()
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-left',
            props: {
              title: `Thank Your`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: `You response has been successfully submitted`,
            },
          })
        }
      });
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
};
</script>